// project imports
import { useNavigate } from "react-router-dom";
import { getDataFromLocalStorage } from "utils/localStore";
import dominos_blue_logo from "../../../assets/images/logos/dominos_blue_logo.webp";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ width, height }: any) => {
  const navigate = useNavigate();
  const store = getDataFromLocalStorage("store") as any;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <img
        onClick={() => {
          if (store) {
            navigate("/");
          }
        }}
        style={{
          cursor: store ? "pointer" : "default",
        }}
        src={dominos_blue_logo}
        alt="Dominos Logo"
        width={width}
        height={height}
      />
    </div>
  );
};

export default LogoSection;
