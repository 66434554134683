import { useRoutes } from "react-router-dom";
import { getDataFromLocalStorage } from "../utils/localStore";
import { AuthenticationRoutes } from "./AuthenticationRoutes";
import { MainAppRoutes } from "./MainAppRoutes";
import useStyles from "./style-jss";

// routes

function AppRoute() {
  const classes = useStyles() as any;
  let element = MainAppRoutes({ classes });
  let element_2 = AuthenticationRoutes({ classes });
  let store = getDataFromLocalStorage("store");

  // eslint-disable-next-line
  if (!store) return useRoutes([element_2]);

  // eslint-disable-next-line
  return useRoutes([element]);
}
export default AppRoute;
