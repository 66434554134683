import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { AppDispatch, RootState } from "store";
import { all_categories } from "store/categoryReducer";
import LoadingComponent from "ui-components/LoadingComponent";
import { replace_url } from "utils/helper";

const Categories = () => {
  const category_slicer = useSelector(
    (state: RootState) => state.category
  ) as any;
  const product_slicer = useSelector(
    (state: RootState) => state.product
  ) as any;
  const offer_slicer = useSelector((state: RootState) => state.offer) as any;
  const language = useSelector(
    (state: RootState) => state.language.language
  ) as any;
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const theme = useTheme() as any;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const [imageLoaded, setImageLoaded] = useState<any>(false);

  const get_all_categories = () => {
    try {
      dispatch(
        all_categories({
          end_point: "product/categories/public",
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    get_all_categories();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="categories-main"
      style={{
        position: "sticky",
        top: 0,
        zIndex: 1300,
        transition: "1s ease",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: isMobile ? 8 : 12,
          paddingTop: isMobile ? 8 : 12,
          alignItems: "center",
          overflow: isTablet ? "scroll" : "",
          gap: 12,
          whiteSpace: "nowrap",
          boxShadow: "0px 2px 2px 0px rgb(173,173,173)",
          paddingInline: isMobile ? 20 : 50,
        }}
      >
        {category_slicer.all_category_data?.map((item: any) => (
          <div key={item.idx}>
            <Link
              to={`/${item.category_code}`}
              style={{ textDecoration: "none" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src={
                      location.pathname === `/${item.category_code}`
                        ? replace_url(item.active_icon)
                        : replace_url(item.inactive_icon)
                    }
                    onLoad={() => setImageLoaded(true)}
                    width={isMobile ? 45 : 60}
                    height={isMobile ? 45 : 60}
                    alt={`${item.category_name?.[language]}`}
                    loading={isMobile ? "lazy" : "eager"}
                  />
                </div>
                <p
                  className={"category-title IBM-Regular"}
                  style={{
                    fontWeight: language === "en" ? "normal" : "bold",
                    fontSize: isMobile ? 16 : 20,
                    color:
                      location.pathname === `/${item.category_code}` &&
                      imageLoaded
                        ? "#D10A0A"
                        : "#000000",
                  }}
                >
                  {item.category_name?.[language]}
                </p>
              </div>
            </Link>
          </div>
        ))}
      </div>

      {category_slicer.activity ? (
        <LoadingComponent activity={category_slicer.activity} />
      ) : product_slicer.activity ? (
        <LoadingComponent activity={product_slicer.activity} />
      ) : offer_slicer.activity ? (
        <LoadingComponent activity={offer_slicer.activity} />
      ) : null}
    </div>
  );
};

export default Categories;
