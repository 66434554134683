const objectsEqual = (o1: any, o2: any): any => {
  let match = false
    if(typeof o1 === 'object' && Object.keys(o1)?.length > 0) {
     match = (Object?.keys(o1)?.length === Object.keys(o2)?.length && Object?.keys(o1)?.every(p => objectsEqual(o1[p], o2[p])))
    }else {
     match = (o1 === o2)
    }
    return match
}

export const arraysAreEqual = (a1: any, a2: any) => {
  let finalMatch = [] as any
  let itemFound = [] as any
  
  if(a1?.length === a2?.length) {
    finalMatch = []
    a1.forEach((i1: any) => {
      itemFound = []
      a2.forEach((i2: any) => { 
        itemFound.push(objectsEqual(i1, i2)) 
      })
        finalMatch.push(itemFound?.some((i: any) => i === true))  
    }) 
  } 
  return finalMatch.every((i: any) => i === true)
}