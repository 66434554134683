import { useEffect } from "react";

const useDisableContextMenu = () => {
  useEffect(() => {
    setTimeout(() => {
      const handleContextMenu = (e: MouseEvent) => {
        e.preventDefault();
      };

      const handleKeyDown = (e: KeyboardEvent) => {
        if (
          e.key === "F12" ||
          (e.ctrlKey && e.shiftKey && e.key === "I") ||
          (e.ctrlKey && e.shiftKey && e.key === "J") ||
          (e.ctrlKey && e.key === "U") ||
          (e.ctrlKey && e.shiftKey && e.key === "C")
        ) {
          e.preventDefault();
          return false;
        }
      };

      document.body.addEventListener("contextmenu", handleContextMenu);
      document.body.addEventListener("keydown", handleKeyDown);

      return () => {
        document.body.removeEventListener("contextmenu", handleContextMenu);
        document.body.removeEventListener("keydown", handleKeyDown);
      };
    }, 3000);
  }, []);
};

export default useDisableContextMenu;
