import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import classNames from "classnames";
import { useUserSettings } from "hooks/useUserSettings";
import { lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "store";
import { handleOpen } from "store/drawerReducer";
import { set_store } from "store/userAddress";
import { header_transalation } from "transalations";
import Loadable from "ui-components/Loadable";
import { authenticated_routes, set_localize_content } from "utils/helper";
import delivery from "../../../../src/assets/images/header/bike-white.svg";
import store_icon2 from "../../../../src/assets/images/header/shop-blue.svg";
import store_icon_1 from "../../../../src/assets/images/header/shop-white.svg";
import LogoSection from "../LogoSection";
import HeaderIcons from "./HeaderIcons";

const SideBar = Loadable(lazy(() => import("../SideBar")));

export default function Header({ classes = {} }: { classes?: any }) {
  const {
    language,
    service_method,
    selected_address,
    location,
    store,
    matchDownMd,
    matchUpMd,
  } = useUserSettings();

  const dispatch = useDispatch();
  const userSlicer = useSelector((state: RootState) => state.user);
  const address = useSelector((state: RootState) => state.address) as any;

  const isAuthenticatedRoute =
    authenticated_routes.includes(location.pathname) ||
    location.pathname.startsWith("/add-address") ||
    location.pathname.startsWith("/address-details");

  useEffect(() => {
    dispatch(set_store(store));
    // eslint-disable-next-line
  }, [JSON.stringify(userSlicer?.delete_address)]);

  const renderserviceMethodChip = () => (
    <Chip
      style={{
        background: "#E31836",
        color: "#fff",
      }}
      className={classNames(classes.header_chip, "Kanit-Regular")}
      avatar={
        <Avatar
          alt="bike"
          src={service_method === "takeaway" ? store_icon_1 : delivery}
          className={classes.header_chip_avatar}
          style={{ width: 30, height: 30 }}
        />
      }
      label={
        service_method === "takeaway"
          ? set_localize_content(header_transalation.branch, language)
          : set_localize_content(header_transalation.home_delivery, language)
      }
      variant="outlined"
    />
  );

  const renderStoreChip = () => (
    <Chip
      className={classNames(classes.header_chip, "Kanit-Regular")}
      avatar={
        <Avatar
          alt="store"
          src={store_icon2}
          className={classes.header_chip_avatar}
          style={{ width: 30, height: 30 }}
        />
      }
      style={{
        background: "#fff",
        outline: "1px solid #0078AE",
        color: "#0078AE",
      }}
      label={
        service_method === "delivery"
          ? selected_address
            ? address?.store?.store_name?.[language]
            : set_localize_content(header_transalation.select_address, language)
          : address?.store?.store_name?.[language]
      }
    />
  );

  return (
    <section className={matchDownMd ? "header-mobile" : "header"}>
      <div style={{ paddingInline: matchDownMd ? 0 : 15 }}>
        <div
          style={{
            alignItems: "center",
            display: matchDownMd ? "block" : "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
          }}
        >
          {matchDownMd && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <IconButton
                sx={{ padding: 0 }}
                onClick={() => dispatch(handleOpen())}
              >
                <MenuIcon style={{ fontSize: "2rem" }} />
              </IconButton>
              <LogoSection width={126} height={27} />
              <div style={{ width: "41px" }}></div>
            </div>
          )}

          {matchUpMd &&
            (store ? (
              <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
                <Stack direction="row" spacing={1}>
                  <Link
                    to="/service-method"
                    state={{
                      change_service: !isAuthenticatedRoute,
                      prev_path: location.pathname,
                    }}
                  >
                    {renderserviceMethodChip()}
                  </Link>
                  <Link
                    to={
                      service_method === "takeaway"
                        ? "/choose-store"
                        : "/addresses"
                    }
                    state={{
                      change_service: !isAuthenticatedRoute,
                      prev_path: location.pathname,
                    }}
                  >
                    {renderStoreChip()}
                  </Link>
                </Stack>
              </div>
            ) : (
              <div></div>
            ))}

          {matchUpMd && <LogoSection width={178} height={38} />}

          {matchUpMd && <HeaderIcons classes={classes} />}
        </div>
      </div>
      {matchDownMd && (
        <nav>
          <SideBar />
        </nav>
      )}
    </section>
  );
}
