import { createSlice } from "@reduxjs/toolkit";
import {
  getDataFromLocalStorage,
  getStringFromLocalStorage,
  saveStringToLocalStorage,
} from "utils/localStore";
const token = getStringFromLocalStorage("token");
const user = getDataFromLocalStorage("user");
const language = getStringFromLocalStorage("language");

interface language_state {
  language: string;
}

const initialState: language_state = {
  language: token ? user?.language || "ge" : language || "ge",
};

const language_slicer = createSlice({
  name: "language",
  initialState,
  reducers: {
    language_ge: (state: language_state) => {
      if (!token) {
        saveStringToLocalStorage("language", "ge");
      }
      return {
        language: "ge",
      };
    },
    language_en: (state: language_state) => {
      if (!token) {
        saveStringToLocalStorage("language", "en");
      }
      return {
        language: "en",
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { language_ge, language_en } = language_slicer.actions;

export default language_slicer.reducer;
