import "./App.css";

import { useSelector } from "react-redux";

import {
  CssBaseline,
  StyledEngineProvider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

// routing
import NavigationScroll from "layout/NavigationScroll";
import AppRoute from "routes";

// defaultTheme
import useDisableContextMenu from "hooks/useDisableContextMenu";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Bounce, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { update_user } from "store/userProfile";
import themes from "themes";
import { is_redirect_store } from "utils/helper";
import { getDataFromLocalStorage } from "utils/localStore";

function App() {
  const theme = useTheme() as any;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const customization = useSelector((state: any) => state.customization);
  const store = getDataFromLocalStorage("store") as any;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useDisableContextMenu();

  useEffect(() => {
    try {
      const worker = new Worker(new URL("workers/UserWorker", import.meta.url));

      worker.postMessage({ type: "GET_USER" });

      worker.onmessage = (e) => {
        const { success, result, error } = e.data;
        if (success) {
          let user = result?.data;
          let user_data = {
            _id: user?._id,
            first_name: user?.first_name,
            last_name: user?.last_name,
            profile_pic: user?.profile_pic,
            loyalty_points: user?.loyalty_points,
            language: user?.language,
          } as any;

          if (user_data?._id) {
            dispatch(update_user(user_data));
          }
        } else {
          console.error("Error from worker:", error);
        }
      };

      const store_worker = new Worker(
        new URL("workers/StoreWorker", import.meta.url)
      );

      if (location.pathname !== "/") {
        store_worker.postMessage({
          type: "GET_STORE",
          store_code: store?.store_code,
        });

        store_worker.onmessage = (e) => {
          const { success, result, error } = e.data;
          if (success) {
            let updated_store = result?.data as any;
            if (updated_store?.store_code) {
              if (is_redirect_store(updated_store) || updated_store?.is_fault) {
                navigate("/");
              }
            }
          } else {
            console.error("Error from worker:", error);
          }
        };
      }

      return () => {
        worker.terminate();
        store_worker.terminate();
      };
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          limit={1}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          transition={isMobile ? Slide : Bounce}
          theme={isMobile ? "light" : "colored"}
          style={
            isMobile
              ? {
                  width: 230,
                  borderRadius: 52,
                  fontSize: 12,
                  marginTop: 25,
                  marginLeft: 130,
                  zIndex: 999999,
                }
              : { zIndex: 999999 }
          }
        />
        <NavigationScroll>
          <CssBaseline />
          <AppRoute />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
